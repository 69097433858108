import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
// import { Link } from 'gatsby';
import Box from 'reusecore/src/elements/Box';
import Text from 'reusecore/src/elements/Text';
// import Image from 'reusecore/src/elements/Image';
import Heading from 'reusecore/src/elements/Heading';
// import Button from 'reusecore/src/elements/Button';
import BannerWrapper from './bannerSection.style';

import FeatureBlock from '../../components/FeatureBlock';
import Container from '../../components/UI/Container';

const BannerSection = ({
  row,
  col,
  titleStyle,
  // btnStyle,
  descriptionStyle,
  // outlineBtnStyle,
  cover,
  title,
  subtitle,
  children
}) => {
  // const ButtonGroup = () => (
  //   <Fragment>
  //     <Link to="/productos">
  //       <Button variant="hoverShadow" title="Nuestros Productos" {...btnStyle} />
  //     </Link>
  //     <Link to="/proyectos">
  //       <Button
  //         title="Revisa algunos proyectos"
  //         variant="textButton"
  //         icon={<i className="flaticon-next" />}
  //         {...outlineBtnStyle}
  //       />
  //     </Link>
  //   </Fragment>
  // );

  return (
    <BannerWrapper id="banner_section" cover={cover}>
      <Container>
        <Box className="row" {...row}>
          <Box className="col" {...col}>
            <FeatureBlock
              title={<Heading as="h1" content={title} {...titleStyle} />}
              description={(
                <Fragment>
                  <Text content={subtitle || children} {...descriptionStyle} />
                </Fragment>
)}
              // button={<ButtonGroup />}
            />
          </Box>
        </Box>
      </Container>
    </BannerWrapper>
  );
};

BannerSection.propTypes = {
  row: PropTypes.object,
  col: PropTypes.object,
  titleStyle: PropTypes.object,
  btnStyle: PropTypes.object,
  descriptionStyle: PropTypes.object,
  outlineBtnStyle: PropTypes.object
};

BannerSection.defaultProps = {
  row: {
    flexBox: true,
    flexWrap: 'wrap',
    ml: '-15px',
    mr: '-15px',
    alignItems: 'center'
  },
  col: {
    pr: '15px',
    pl: '15px',
    width: ['100%', '100%', '100%', '60%', '65%']
  },
  titleStyle: {
    fontSize: ['26px', '34px', '42px', '42px', '47px'],
    fontWeight: '700',
    color: '#fff',
    letterSpacing: '-0.025em',
    mb: ['20px', '25px'],
    lineHeight: '1.31'
  },
  descriptionStyle: {
    fontSize: ['16px', '18px', '20px', '20px', '24px'],
    color: '#fff',
    lineHeight: '30px',
    mb: '0',
    maxWidth: '550px'
  },
  btnStyle: {
    minWidth: ['120px', '156px'],
    fontSize: '15px',
    fontWeight: '700',
    borderRadius: '6px'
  },
  outlineBtnStyle: {
    minWidth: ['130px', '156px'],
    fontSize: '16px',
    fontWeight: '700',
    color: '#fff',
    p: '5px 10px'
  }
};

export default BannerSection;
