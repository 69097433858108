import styled, { css } from 'styled-components';

const CategoryGridWrapper = styled.div``;

export const GridContainer = styled.div`
  display: grid;
  grid-auto-columns: 1fr;
  grid-template-areas: 'a a';
  grid-gap: 1rem;
  margin-top: 10px;
  padding: 1rem 2.5rem;
  ${({ template }) =>
    template &&
    css`
      grid-template-columns: ${template};
    `}
    ${({ single }) =>
      single &&
      css`
        grid-template-areas: 'a';
      `}
  ${({ row }) =>
    row &&
    css`
      margin-top: 0px;
      grid-auto-flow: row;
    `}
  @media only screen and (max-width: 667px) {
    display: block;
  }

`;

export const CategoryWrapper = styled.div`
  position: relative;
  ${'' /* padding: 3.75rem 1.875rem; */}
  padding: 30px 40px;
  min-height: 50vh;
  border-radius: 4px;
  height: 100%;
  background: ${({ theme }) => theme.colors.primary};
  color: white;
  z-index: 1;
  ${({ height }) =>
    height &&
    css`
      min-height: ${height};
    `}
  ${({ cover }) =>
    cover &&
    css`
      background-image: linear-gradient(
          to right,
          rgba(0, 0, 0, 0.4),
          rgba(0, 0, 0, 0.2),
          rgba(0, 0, 0, 0)
        ),
        url(${cover});
      background-repeat: no-repeat;
      background-size: cover;
      /* background-position: center; */
      &:before {
        content: '';
        display: block;
        height: 100%;
        position: absolute;
        width: 100%;
        background-color: ${({ theme }) => theme.colors.normalLayer};
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: -1;
      }
    `}
  @media only screen and (max-width: 667px) {
    min-height: 45vh;
    margin-top: 10px;
    padding: 40px 50px;
  }

  h2 {
    ${'' /* color: #fff;
    margin-bottom: 2rem;
    font-size: 2.5rem; */}
    margin-top: 1em;
    font-size: 1.825rem;
    color: #ffffff;
    line-height: 2.375rem;
    font-weight: 400;
    max-width: 280px;
  }
  p {
    margin-bottom: 2rem;
    font-size: 1.125rem;
    line-height: 1.5rem;
  }
  a {
    position: absolute;
    bottom: 1.5rem;
    right: 2rem;
  }
  button {
    border-radius: 5px;
    font-size: 0.95rem;
    font-weight: 700;
    transition: 0.2s all;
    ${'' /* background-color: ${({ theme }) => theme.colors.primary}; */}
    background-color: #fff;
    border-color: #fff;
    color: ${({ theme }) => theme.colors.textColor};
    &:hover {
      ${'' /* background-color: #fff; */}
      background-color: ${({ theme }) => theme.colors.primary};
      border-color: ${({ theme }) => theme.colors.primary};
      color: ${({ theme }) => theme.colors.white};
      box-shadow: ${({ theme }) => theme.colors.baseBoxShadow};
    }
  }
`;

export default CategoryGridWrapper;
